import adminLayoutJson from '../layouts/adminLayout.json';
import providerLayoutJson from '../layouts/providerLayout.json';

const backendDomain = process.env.BACKEND_DOMAIN;
type LayoutShape = (typeof adminLayoutJson)['lg'];
interface LayoutType {
  [breakpoint: string]: LayoutShape;
}
const adminLayout: LayoutType = adminLayoutJson;
const providerLayout: LayoutType = providerLayoutJson;

export const query = async (url: string, options: RequestInit) => {
  const res = await fetch(url, options);
  if (!res.ok) {
    const error = {
      info: await res.json(),
      status: res.status
    };
    throw error;
  }

  return await res.json();
};

export const queryBackendGet = (
  endpoint: string,
  accessToken: string,
  params?: Array<Array<string>>
) => {
  const url = new URL(`${backendDomain}/${endpoint}`);
  if (params) {
    for (let i = 0; i < params.length; ++i) {
      url.searchParams.append(params[i][0], params[i][1]);
    }
  }

  return query(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });
};

export const queryBackendPost = (endpoint: string, accessToken: string, body: object) =>
  query(`${backendDomain}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(body)
  });
export const queryBackendPut = (endpoint: string, accessToken: string, body: object) =>
  query(`${backendDomain}/${endpoint}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(body)
  });

export const SaveSelections = async (
  getToken: () => Promise<string>,
  peerpollId: string,
  pollSelection: string[]
) => {
  const token = await getToken();
  fetch(`${backendDomain}/polls/SaveSubmission?` + new URLSearchParams({ id: peerpollId }), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(pollSelection)
  });
};

export const updateLayoutMutation = async (
  url: string,
  {
    arg
  }: {
    arg: { layout_blob: string; breakpoint: string; role: string; getToken: () => Promise<string> };
  }
) => {
  const { getToken, ...body } = arg;
  const token = await getToken();

  return fetch(`${backendDomain}/${url}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  });
};

export const createDefaultLayouts = async (getToken: () => Promise<string>, role: string) => {
  const token = await getToken();

  if (role === 'admin') {
    fetch(`${backendDomain}/create/layout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ layout_blob: adminLayout['lg'], breakpoint: 'lg', role: 'admin' })
    });
    fetch(`${backendDomain}/create/layout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ layout_blob: adminLayout['md'], breakpoint: 'md', role: 'admin' })
    });
    fetch(`${backendDomain}/create/layout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ layout_blob: adminLayout['sm'], breakpoint: 'sm', role: 'admin' })
    });

    fetch(`${backendDomain}/create/layout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        layout_blob: providerLayout['lg'],
        breakpoint: 'lg',
        role: 'provider'
      })
    });
    fetch(`${backendDomain}/create/layout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        layout_blob: providerLayout['md'],
        breakpoint: 'md',
        role: 'provider'
      })
    });
    fetch(`${backendDomain}/create/layout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        layout_blob: providerLayout['sm'],
        breakpoint: 'sm',
        role: 'provider'
      })
    });
  } else {
    fetch(`${backendDomain}/create/layout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        layout_blob: providerLayout['lg'],
        breakpoint: 'lg',
        role: 'provider'
      })
    });
    fetch(`${backendDomain}/create/layout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        layout_blob: providerLayout['md'],
        breakpoint: 'md',
        role: 'provider'
      })
    });
    fetch(`${backendDomain}/create/layout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        layout_blob: providerLayout['sm'],
        breakpoint: 'sm',
        role: 'provider'
      })
    });
  }
};

export const resetToDefaultLayout = async (
  getToken: () => Promise<string>,
  breakpoint: string,
  role: string
) => {
  const token = await getToken();
  const defaultLayout: LayoutShape =
    role === 'admin' ? adminLayout[breakpoint] : providerLayout[breakpoint];

  fetch(`${backendDomain}/update/layout`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      layout_blob: JSON.stringify(defaultLayout),
      breakpoint: breakpoint,
      role: role
    })
  });
};
