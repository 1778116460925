import React from 'react';
import { Link, Tile } from '@carbon/react';
import { ArrowRight } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';
import styles from './WidgetContainer.module.scss';
interface WidgetContainerProps {
  page?: string;
  header: string;
  subheader?: string;
  children: React.ReactNode;
  style?: object;
  inlineStyle?: object;
  noContainer?: boolean;
  noContainerStyles?: string;
  link?: boolean;
}
const WidgetContainer = React.forwardRef<HTMLDivElement, WidgetContainerProps>(
  (
    {
      noContainer,
      noContainerStyles,
      page,
      header,
      link,
      subheader,
      children,
      style,
      inlineStyle,
      ...other
    },
    ref
  ) => {
    const navigate = useNavigate();
    if (noContainer) {
      return (
        <div style={{ ...inlineStyle, ...style }} ref={ref} {...other}>
          <div className={`${styles.separateWrapper} ${noContainerStyles}`}>{children}</div>
        </div>
      );
    }

    return (
      <Tile ref={ref} style={{ ...inlineStyle, ...style }} {...other}>
        <div className={styles.container}>
          <div className={styles.titleWrapper} id="wc-title">
            <div className={styles.upperContent}>
              <p className={styles.header}>{header}</p>
              <p className={styles.subheader}>{subheader}</p>
            </div>
            {link && (
              <Link
                className={styles.link}
                onClick={() => {
                  navigate(page);
                }}>
                <p>{`View ${header}`} </p>
                &nbsp;&nbsp;
                <ArrowRight />
              </Link>
            )}
          </div>
          <div className={styles.contentContainer}>{children}</div>
        </div>
      </Tile>
    );
  }
);
WidgetContainer.displayName = 'WidgetContainer';
export default WidgetContainer;
