import './index.scss';
// Styles for react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from './contexts/ThemeProvider';
import { RoleProvider } from './contexts/RoleProvider';
import Routing from './routing/Routing';
import SwrConfigProvider from './swr/SwrConfigProvider';

ReactDOM.render(
  <React.StrictMode>
    <SwrConfigProvider>
        <ThemeProvider>
          <RoleProvider>
            <Routing />
          </RoleProvider>
        </ThemeProvider>
    </SwrConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
