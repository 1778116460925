import { ReactNode } from 'react';
import { Grid, Column } from '@carbon/react';
import RGLDashboard from '../components/RGLDashboard/RGLDashboard';
import styles from './dashboards.module.scss';

const DashboardBase = ({ children }: { children: ReactNode }) => {
  return (
    <Grid className={styles.grid} fullWidth>
      <Column className={styles.column} lg={16} md={8} sm={4}>
        <RGLDashboard>{children}</RGLDashboard>
      </Column>
    </Grid>
  );
};

export default DashboardBase;
