import InformationalTile from './InformationalTile';
import { useBackend } from '../../../swr/useBackend';

interface CMEType {
  totalCredits: number;
}

const CMEProgess = () => {
  const { data, isLoading, error } = useBackend<CMEType>({
    endpoint: 'api/content/cme-credit',
    method: 'get',
    params: {
      user_id: '003A000001pWYIpIAO',
      begin_date: '2023-01-01T00:00:00,000',
      end_date: '2023-01-01T00:00:00,000'
    }
  });

  // TODO: Calculate the range based on amt of peer credits
  return (
    <>
      {error ? (
        <div>An unexpect error occurred. Please try again later.</div>
      ) : (
        <InformationalTile
          title="CME Credits Earned"
          subTitle="YTD"
          value={data?.totalCredits}
          range={25}
          valueType="isPercentile"
          icon="course"
          loading={isLoading}
        />
      )}
    </>
  );
};

CMEProgess.displayName = 'CMEProgess';
export default CMEProgess;
