import { forwardRef } from 'react';
import { useRoleContext } from '../../../contexts/RoleProvider';
import WidgetContainer from '../../DashboardWidgets/WidgetContainer/WidgetContainer';
import CMEProgess from './CMEProgress';
import UpToDate from './UpToDate';
import Rewards from './Rewards';
import TotalActiveUsers from './TotalActiveUsers';
import styles from '../InformationalHalfbox/InformationalHalfbox.modules.scss';

const InformationalHalfbox = forwardRef<HTMLDivElement>(({ ...other }, ref) => {
  const { role } = useRoleContext();
  return (
    <WidgetContainer
      ref={ref}
      header=""
      page="/"
      {...other}
      noContainer
      noContainerStyles={styles.wc}>
      {role === 'admin' && (
        <>
          <CMEProgess />
          <UpToDate />
          <Rewards />
          <TotalActiveUsers />
        </>
      )}
      {role === 'provider' && (
        <>
          <CMEProgess />
          <UpToDate />
          <Rewards />
        </>
      )}
    </WidgetContainer>
  );
});

InformationalHalfbox.displayName = 'InformationalHalfbox';
export default InformationalHalfbox;
