import { useAuth0 } from '@auth0/auth0-react';
import { Fetcher, Middleware, SWRHook } from 'swr';

const token: Middleware = (useSwrNext: SWRHook) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  return (key, fetcher, config) => {
    const fetcherWithToken = async (...args: Parameters<Fetcher>) => {
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (error) {
        logout({ returnTo: window.location.origin });
      }
      return fetcher(...args, token);
    };

    return useSwrNext(key, fetcherWithToken, config);
  };
};

export default token;
