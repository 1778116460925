import React from 'react';
import WidgetContainer from '../WidgetContainer/WidgetContainer';
import styles from './NewsFeed.module.scss';
import { Link as IconLink } from '@carbon/icons-react';
import { UnorderedList, Link } from '@carbon/react';
import { useDashboardContext } from '../../../contexts/DashboardProvider';
import { useBackend } from '../../../swr/useBackend';

interface NewsFeedData {
  allRecentnews: NewsFeedItem[];
}

interface NewsFeedItem {
  id: string;
  heading: string;
  link: string;
  specialty: string;
  state: string;
}

const NewsFeed = React.forwardRef<HTMLDivElement>(({ ...other }, ref) => {
  const { mutable } = useDashboardContext();
  const { data, error } = useBackend<NewsFeedData>({
    endpoint: 'api/content/dato_content',
    method: 'POST',
    body: {
      query: `{
        allRecentnews(orderBy: _createdAt_ASC, first: "5") {
          id
          heading
          link
          specialty
          state
        }
      }`
    }
  });

  return (
    <WidgetContainer header="Recent News" ref={ref} {...other} page="/news">
      {error ? (
        <div>No News Found</div>
      ) : (
        <UnorderedList>
          {data?.allRecentnews &&
            data.allRecentnews.map((newsItem: { id: React.Key; link: string; heading: string }) => (
              <div className={styles.divider} key={newsItem.id}>
                <div>
                  <IconLink size="20" />
                </div>
                <div className={styles.content}>
                  <Link
                    className={styles.a}
                    href={newsItem.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={mutable}>
                    {newsItem.heading}
                  </Link>
                </div>
              </div>
            ))}
        </UnorderedList>
      )}
    </WidgetContainer>
  );
});
NewsFeed.displayName = 'NewsFeed';

export default NewsFeed;
