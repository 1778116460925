import { Outlet, useNavigate } from 'react-router-dom';
import { Auth0Provider, AppState } from '@auth0/auth0-react';

export const Auth0ProviderWithNavigate = (): JSX.Element => {
  const domain = process.env.AH_AUTH0_DOMAIN;
  const clientId = process.env.AH_AUTH0_CLIENT_ID;
  const redirectUri = process.env.AH_AUTH0_CALLBACK_URL;

  const navigate = useNavigate();
  const onRedirectcallback = (appState: AppState) => {
    navigate((appState && appState.returnTo) || '/');
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: 'https://advice-hub.magmutual.com',
        scope: 'admin:all read:all openid profile email',
        connection: 'CyberArk-SAML',
        redirect_uri: redirectUri
      }}
      onRedirectCallback={onRedirectcallback}>
      <Outlet />
    </Auth0Provider>
  );
};
