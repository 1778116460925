import React from 'react';
import { ListItem, Link, Tag } from '@carbon/react';
import WidgetContainer from '../WidgetContainer/WidgetContainer';
import { useBackend } from '../../../swr/useBackend';
import styles from './Article.module.scss';

interface Article {
  id: string;
  image: string;
  href: string;
  title: string;
  tag: string;
}

const ArticleList = React.forwardRef<HTMLDivElement>(({ ...other }, ref) => {
  // TODO: Replace this variable with the user's specialty
  const userSpecialty = { specialty: 'dermatology' };
  const { data } = useBackend<Article[]>({
    endpoint: 'api/content/recent-advice-content',
    method: 'get',
    params: userSpecialty
  });

  return (
    <WidgetContainer header="Recent Advice Posts" {...other} ref={ref}>
      {data?.map((ArticleItem) => (
        <ListItem key={ArticleItem.id} className={styles.itemContainer}>
          <div className={styles.leftArea}>
            <img src={ArticleItem.image} className={styles.imageContainer} />
          </div>
          <div className={styles.rightArea}>
            <Link
              href={ArticleItem.href}
              className={styles.titleContainer}
              target="_blank"
              rel="noreferrer noopener">
              {ArticleItem.title}
            </Link>
            <Tag type="gray">{ArticleItem.tag}</Tag>
          </div>
        </ListItem>
      ))}
    </WidgetContainer>
  );
});
ArticleList.displayName = 'ArticleList';

export default ArticleList;
