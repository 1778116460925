import { useAuth0 } from '@auth0/auth0-react';
import styles from './Profile.module.scss';

const ProfileDropdownItem = (): JSX.Element => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div className={styles.profileDropdownItem}>
        <img src={user.picture} alt={user.name} />
        <p>{user.name}</p>
      </div>
    )
  );
};

export default ProfileDropdownItem;
