import React from 'react';
import { ContentSwitcher, Switch, Loading } from '@carbon/react';
import { useBackend } from '../../../swr/useBackend';
import styles from './TopRiskDrivers.module.scss';
import { SimpleBarChart } from '@carbon/charts-react';
import WidgetContainer from '../../DashboardWidgets/WidgetContainer/WidgetContainer';
import { useTheme } from '../../../contexts/ThemeProvider';

interface RiskDriverType {
  driver: {
    clinicalCause?: string;
    nonClinicalFactor?: string;
    averageIndemnity?: number;
    percentClaims?: number;
    casesWithIndemnity?: number;
    riskDriver?: string;
    clinicalStrategies?: string[];
    operationalStrategies?: string[];
  };
  null: {
    clinicalCause: null;
    nonClinicalFactor: null;
    averageIndemnity: null;
    percentClaims: null;
    casesWithIndemnity: null;
    riskDriver: null;
    clinicalStrategies: null[];
    operationalStrategies: null[];
  };
}

// TODO: Add prop for which specialty to look for
const TopRiskDrivers = React.forwardRef<HTMLDivElement>(({ ...other }, ref) => {
  const { data, isLoading } = useBackend<RiskDriverType>({
    endpoint: 'api/content/top-risk-driver',
    method: 'get',
    params: { specialty: 'Gastroenterology' }
  });
  const { theme } = useTheme();

  const barChartOptions = {
    grid: {
      x: {
        enabled: false
      },
      y: {
        enabled: true
      }
    },
    axes: {
      left: {
        mapsTo: 'value'
      },
      bottom: {
        mapsTo: 'group',
        scaleType: 'labels',
        visible: false
      }
    },
    bars: {
      width: 40
    },
    toolbar: {
      enabled: false
    },
    legend: {
      enabled: true,
      position: 'bottom',
      clickable: false,
      alignment: 'left',
      truncation: {
        numCharacter: 100
      }
    },
    height: '100%',
    width: '100%',
    theme: theme === 'dark-theme' ? 'g90' : 'white'
  };

  const [frequencyData, setFrequencyData] = React.useState([]);
  const [severityData, setSeverityData] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);

  const handleTabClick = (selectedIndex: { name: string }) => {
    if (selectedIndex.name === 'freq') {
      setChartData(frequencyData);
    } else {
      setChartData(severityData);
    }
  };

  if (!isLoading && data && !data.null && chartData.length === 0) {
    const freq = [];
    const sever = [];
    for (const [group, value] of Object.entries(data)) {
      freq.push({
        group: group,
        value: Math.trunc(value.percent_claims * 100)
      });
      sever.push({
        group: group,
        value: value.average_indemnity
      });
    }

    setFrequencyData(freq);
    setSeverityData(sever);
    setChartData(frequencyData);
  }

  return (
    <WidgetContainer header="Top Risk Drivers" page="/articles" {...other} ref={ref} link>
      <div className={styles.container}>
        {isLoading ? (
          <Loading withOverlay={false} />
        ) : chartData.length > 0 ? (
          <>
            <ContentSwitcher onChange={handleTabClick}>
              <Switch name="freq" text="Frequency" />
              <Switch name="sever" text="Severity" />
            </ContentSwitcher>
            <div className={styles.content}>
              <SimpleBarChart
                data={chartData}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                options={barChartOptions}
              />
            </div>
          </>
        ) : (
          <p className={styles.noData}>NO DATA FOUND</p>
        )}
      </div>
    </WidgetContainer>
  );
});

TopRiskDrivers.displayName = 'TopRiskDrivers';
export default TopRiskDrivers;
