import React from 'react';

const ThemeContext = React.createContext<{
  theme: string;
  setTheme: (state: string) => void;
}>(null);
export const useTheme = () => React.useContext(ThemeContext);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = React.useState(() => localStorage.getItem('theme') || 'dark-theme');
  return <ThemeContext.Provider value={{ theme, setTheme }} children={children} />;
};
