import DashboardBase from './DashboardBase';
import NewsFeed from '../components/DashboardWidgets/NewsFeed/NewsFeed';
import InformationalHalfbox from '../components/DashboardWidgets/InformationalHalfbox/InformationalHalfbox';
import PeerPoll from '../components/DashboardWidgets/PeerPoll/PeerPoll';
import WelcomeMessage from '../components/DashboardWidgets/WelcomeMessage/WelcomeMessage';
import QuickLinkContainer from '../components/DashboardWidgets/QuickLinkTile/QuickLinkContainer';
import ArticleList from '../components/DashboardWidgets/ArticleList/ArticleList';
import TopRiskDrivers from '../components/DashboardWidgets/TopRiskDrivers/TopRiskDrivers';
import QuickAdviceCarousel from '../components/DashboardWidgets/QuickAdvice/QuickAdviceCarousel';
import AssignmentTile from '../components/DashboardWidgets/Assignment/AssignmentTile';

const Dashboard = ({ selectedRole }: { selectedRole: string }) => {
  return (
    <>
      {selectedRole === 'admin' && (
        <DashboardBase>
          <WelcomeMessage key="column-one-top" />
          <QuickLinkContainer key="column-one-middle" />
          <ArticleList key="column-one-bottom" />
          <InformationalHalfbox key="column-two-top" />
          <AssignmentTile key="column-two-bottom" />
          <NewsFeed key="column-three-top" />
          <PeerPoll key="column-three-bottom" id="wgt-peerpoll" />
          <TopRiskDrivers key="column-four-top" />
          <QuickAdviceCarousel key="column-four-bottom" />
        </DashboardBase>
      )}
      {selectedRole === 'provider' && (
        <DashboardBase>
          <WelcomeMessage key="column-one-top" />
          <QuickLinkContainer key="column-one-middle" />
          <ArticleList key="column-one-bottom" />
          <InformationalHalfbox key="column-two-top" />
          <AssignmentTile key="column-two-bottom" />
          <NewsFeed key="column-three-top" />
          <PeerPoll key="column-three-bottom" id="wgt-peerpoll" />
          <TopRiskDrivers key="column-four-top" />
          <QuickAdviceCarousel key="column-four-bottom" />
        </DashboardBase>
      )}
    </>
  );
};

export default Dashboard;
