import InformationalTile from './InformationalTile';

const Rewards = () => {
  return (
    <InformationalTile
      title="Advice Rewards Earned"
      subTitle="YTD"
      value="$1,750"
      range={50}
      valueType="isParticipation"
      icon="trophy"
    />
  );
};

Rewards.displayName = 'Rewards';
export default Rewards;
