import React from 'react';

interface IDashboardContext {
  mutable: boolean;
  setMutable: (state: boolean) => void;
}
const DashboardContext = React.createContext<IDashboardContext>(null);
export const useDashboardContext = () => React.useContext(DashboardContext);

interface DashboardProviderProps {
  children: React.ReactNode;
}
export const DashboardProvider = ({ children }: DashboardProviderProps) => {
  const [mutable, setMutable] = React.useState(false);

  return <DashboardContext.Provider value={{ mutable, setMutable }} children={children} />;
};
