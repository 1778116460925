import { ClickableTile } from '@carbon/react';
import styles from './QuickLinkTile.module.scss';
import { Launch } from '@carbon/icons-react';
import { useDashboardContext } from '../../../contexts/DashboardProvider';

interface QuickLinkTileProps {
  title: string;
  icon: string;
  href: string;
}

const QuickLinkTile = ({ title, icon, href }: QuickLinkTileProps): JSX.Element => {
  const { mutable } = useDashboardContext();
  return (
    <ClickableTile
      className={styles.content}
      href={href}
      disabled={mutable}
      target="_blank"
      rel="noopener noreferrer">
      <div className={styles.details}>
        <img src={icon} className={styles.image} />
        <p>{title}</p>
      </div>
      <Launch size={20} />
    </ClickableTile>
  );
};

export default QuickLinkTile;
