import React from 'react';
import { useDashboardContext } from './contexts/DashboardProvider';
import { useRoleContext } from './contexts/RoleProvider';
import { Dropdown } from '@carbon/react';
import Dashboard from './dashboards/Dashboard';

const roleType = ['Admin', 'Provider'];

function App() {
  const { mutable } = useDashboardContext();
  const { role, setRole } = useRoleContext();
  const [currentRole, setCurrentRole] = React.useState(roleType[0]);

  return (
    <>
      {mutable}
      <Dropdown
        id="role-dropdown"
        titleText="Available roles"
        helperText="Please select an available role to view the dashboard."
        label="Please choose a role"
        items={roleType}
        onChange={({ selectedItem }: { selectedItem: string }) => {
          setRole(selectedItem.toLowerCase());
          setCurrentRole(selectedItem);
        }}
        selectedItem={currentRole}
      />
      <Dashboard selectedRole={role} />
    </>
  );
}

export default App;
