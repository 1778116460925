import { Tile, Loading } from '@carbon/react';
import styles from '../InformationalHalfbox/InformationalHalfbox.modules.scss';
import { Events, Trophy, Laptop, Course } from '@carbon/icons-react';

interface InformationalTileProps {
  title: string;
  subTitle?: string;
  value: number | string;
  range?: number;
  valueType: 'isPercentile' | 'isRewards' | 'isParticipation';
  icon: string;
  loading: boolean;
}
const InformationalTile = ({
  title,
  subTitle,
  value,
  range,
  valueType,
  icon,
  loading
}: InformationalTileProps): JSX.Element => {
  const subText = (range: number): JSX.Element => {
    switch (valueType) {
      case 'isPercentile':
        return (
          <p>
            You are in the <strong>{range}th percentile</strong> compared to your peers
          </p>
        );
      case 'isRewards':
        return (
          <p>
            You have earned <strong>{range}%</strong> of the rewards available
          </p>
        );
      case 'isParticipation':
        return (
          <p>
            You have a <strong>{range}%</strong> participation rate
          </p>
        );
      default:
        return null;
    }
  };

  const percentileRange = (range: number) => {
    // If the count is 25 or less, return the color red
    if (range <= 25) return '#DC143C';
    // if the count is greater than or equal to 25 and less than 75, return the color orange
    else if (range > 25 && range < 70) return '#FFA500';
    // if the count is greater than or equal to 75, return the color green
    else if (range >= 70) return '#008000';
  };

  const icons = [
    { name: 'course', component: <Course size={24} /> },
    { name: 'laptop', component: <Laptop size={24} /> },
    { name: 'events', component: <Events size={24} /> },
    { name: 'trophy', component: <Trophy size={24} /> }
  ];
  function getIcon(iconName: string): JSX.Element {
    const myIcon = icons.find((obj) => obj.name === iconName);
    if (myIcon) {
      return myIcon.component;
    }
    return null;
  }
  const scoreColor = percentileRange(range);
  return (
    <Tile className={styles.container} style={{ borderLeft: `10px solid ${scoreColor} ` }}>
      {loading ? (
        <div className={styles.loading}>
          <Loading withOverlay={false} />
        </div>
      ) : (
        <>
          <div className={styles.topContent}>
            <div className={styles.topTitle}>
              <div className={styles.titleSize}>{title}</div>
              <div className={styles.subTitle}>{subTitle}</div>
            </div>
            {getIcon(icon)}
          </div>
          <div className={styles.middleAlignment}>
            <h2 style={{ color: scoreColor }}>{value}</h2>
            <div className={styles.subTextWidth}>{subText(range)}</div>
          </div>
        </>
      )}
    </Tile>
  );
};
export default InformationalTile;
