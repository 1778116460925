import { Switcher, SwitcherItem, SwitcherDivider, Toggle } from '@carbon/react';
import { Chat, Settings } from '@carbon/icons-react';

import { useDashboardContext } from '../../contexts/DashboardProvider';
import ProfileItem from '../Profile/ProfileDropdownItem';
import styles from './AccountDropdown.module.scss';

const AccountDropdown = () => {
  const { mutable, setMutable } = useDashboardContext();

  return (
    <Switcher aria-label="Switcher Container">
      <SwitcherItem className={styles.dropdownItems} aria-label="UserInfo" href="#">
        <div className={styles.profile}>
          <ProfileItem />
        </div>
      </SwitcherItem>
      <SwitcherDivider />
      <SwitcherItem className={styles.dropdownItems} aria-label="Mentions" href="#">
        <Chat />
        <p className={styles.dropdownText}>Mentions</p>
      </SwitcherItem>
      <SwitcherItem className={styles.dropdownItems} aria-label="Settings" href="#">
        <Settings />
        <p className={styles.dropdownText}>Settings</p>
      </SwitcherItem>
      <Toggle
        id="layoutToggle"
        className={styles.dropdownItems}
        defaultToggled={true}
        labelA="Unlocked Layout"
        labelText=""
        labelB="Locked Layout"
        size="sm"
        onToggle={() => setMutable(!mutable)}
      />
    </Switcher>
  );
};

export default AccountDropdown;
