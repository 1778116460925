import InformationalTile from './InformationalTile';

const UpToDate = () => {
  return (
    <InformationalTile
      title="UpToDate Searches"
      subTitle="YTD"
      value={74}
      range={75}
      valueType="isPercentile"
      icon="laptop"
    />
  );
};

UpToDate.displayName = 'UpToDate';
export default UpToDate;
