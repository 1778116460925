import { useRef, useState, useEffect } from 'react';
import {
  Header,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel,
  SkipToContent
} from '@carbon/react';
import { Notification, UserAvatarFilled, Asleep, Light } from '@carbon/icons-react';
import AccountDropdown from '../AccountDropdown/AccountDropdown';
import MagLogoSvg from '../icons/MagLogoSvg';
import { useTheme } from '../../contexts/ThemeProvider';
import styles from './SiteHeader.module.scss';
import { useNavigate } from 'react-router-dom';

const SiteHeader = (): JSX.Element => {
  const navigate = useNavigate();

  const accountPanelRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const { theme, setTheme } = useTheme();
  const toggleTheme = () => {
    if (theme === 'light-theme') {
      setTheme('dark-theme');
      localStorage.setItem('theme', 'dark-theme');
    } else {
      setTheme('light-theme');
      localStorage.setItem('theme', 'light-theme');
    }
  };
  useEffect(() => {
    window.document.body.className = theme;
  }, [theme]);
  useEffect(() => {
    const checkClickOutside = (e: MouseEvent) => {
      if (
        open &&
        accountPanelRef.current &&
        e.target instanceof Node &&
        !accountPanelRef.current.contains(e.target)
      ) {
        setOpen(!open);
      }
    };

    window.document.addEventListener('click', checkClickOutside);
    return () => window.document.removeEventListener('click', checkClickOutside);
  }, [open]);

  return (
    <>
      <Header aria-label="Advice Hub">
        <SkipToContent />
        <HeaderName onClick={() => navigate('/')} prefix="">
          {theme === 'dark-theme' ? (
            <MagLogoSvg height="1.5rem" fill="#fff" />
          ) : (
            <MagLogoSvg height="1.5rem" fill="#000" />
          )}
        </HeaderName>
        <HeaderGlobalBar>
          <HeaderGlobalAction
            aria-label="Mode"
            kind="ghost"
            onClick={() => {
              toggleTheme();
            }}>
            {theme === 'light-theme' ? <Light /> : <Asleep />}
          </HeaderGlobalAction>
          <HeaderGlobalAction aria-label="Notifications" kind="ghost">
            <Notification />
          </HeaderGlobalAction>
          <HeaderGlobalAction
            kind="ghost"
            aria-label="Account"
            tooltipAlignment="end"
            isActive
            onClick={() => setOpen(!open)}>
            <UserAvatarFilled />
          </HeaderGlobalAction>
        </HeaderGlobalBar>
        <HeaderPanel
          className={styles.panel}
          aria-label="Account Dropdown Panel"
          expanded={open}
          ref={accountPanelRef}>
          <AccountDropdown />
        </HeaderPanel>
      </Header>
    </>
  );
};

export default SiteHeader;
