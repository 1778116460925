import { ReactNode, useEffect } from 'react';
import { Content, Loading } from '@carbon/react';
import { useAuth0 } from '@auth0/auth0-react';
import useSWR from 'swr';
import { queryBackendGet, queryBackendPost, createDefaultLayouts } from '../../swr/fetchers';
import SiteHeader from '../SiteHeader/SiteHeader';
import { DashboardProvider } from '../../contexts/DashboardProvider';

const Layout = ({ children }: { children: ReactNode }): JSX.Element => {
  const { isLoading: readingUser, error: userError } = useSWR('read/user', queryBackendGet);
  const {
    data: createdUser,
    isLoading: creatingUser,
    error: createUserError
  } = useSWR(!readingUser && userError ? 'create/user' : null, queryBackendPost);

  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    if (!creatingUser && createdUser) {
      // TODO: Pass in user's role; making default admin for now
      createDefaultLayouts(getAccessTokenSilently, 'admin');
    }
  });

  // TODO: Show Error Page
  if (createUserError) {
    return <div>ERROR COULD NOT CREATE USER</div>;
  }

  return (
    <DashboardProvider>
      {readingUser || creatingUser ? (
        <Loading />
      ) : (
        <>
          <SiteHeader />
          <Content>{children}</Content>
        </>
      )}
    </DashboardProvider>
  );
};

export default Layout;
