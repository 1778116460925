import { SWRConfig } from 'swr';
import token from './token';

interface SwrConfigProviderProps {
  children: React.ReactNode;
}

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: true,
  shouldRetryOnError: false,
  use: [token]
};

const SwrConfigProvider = ({ children }: SwrConfigProviderProps) => {
  return <SWRConfig value={options}>{children}</SWRConfig>;
};

export default SwrConfigProvider;
