import { forwardRef, useEffect, useState } from 'react';
import { Dropdown, Tile, Loading } from '@carbon/react';
import { useAuth0 } from '@auth0/auth0-react';
import WidgetContainer from '../WidgetContainer/WidgetContainer';
import styles from '../WelcomeMessage/WelcomeMessage.module.scss';
import { useDashboardContext } from '../../../contexts/DashboardProvider';
import { useRoleContext } from '../../../contexts/RoleProvider';

type Dict = {
  [key: string]: string[];
};

const WelcomeMessage = forwardRef<HTMLDivElement>(({ ...other }, ref) => {
  const { user, isLoading } = useAuth0();
  const { mutable } = useDashboardContext();
  const { role } = useRoleContext();
  // TODO: Get this from 'user' from 'useAuth0' hook
  const userSFRole = 'Admin Provider';

  const onlyAdminDashboards = [
    'My Dashboard (Admin)',
    'Team Member A',
    'Team Member B',
    'Team Member C'
  ];
  const providerAdminDashboards = [
    'My Dashboard (Admin)',
    'My Dashboard (Physician)',
    'Team Member A',
    'Team Member B',
    'Team Member C'
  ];
  // TODO: Populate each dashboard's specialties with live user data
  // Currently uses dummy data
  const specialties: Dict = {
    'My Dashboard (Admin)': ['Cardiology', 'Dermatology', 'Otolaryngology', 'Pediatrics'],
    'My Dashboard (Physician)': ['Dermatology', 'Pediatrics'],
    'Team Member A': ['Cardiology', 'Dermatology', 'Pediatrics'],
    'Team Member B': ['Dermatology', 'Pediatrics'],
    'Team Member C': ['Otolaryngology']
  };

  const dashboards =
    userSFRole === 'Admin Provider' ? providerAdminDashboards : onlyAdminDashboards;
  const [selected, setSelected] = useState(dashboards[0]);
  const [selectedSpecialty, setSelectedSpecialty] = useState(specialties[selected][0]);
  interface SelectedItemType {
    selectedItem: string;
  }

  // TODO: Add dashboard logic to changeDashboard
  const changeDashboard = (newSelection: string) => {
    setSelected(newSelection);
    !specialties[newSelection].includes(selectedSpecialty) &&
      setSelectedSpecialty(specialties[newSelection][0]);
  };
  const changeSpecialty = (newSelection: string) => setSelectedSpecialty(newSelection);

  const currentDate = new Date();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  const formattedDate = currentDate.toLocaleDateString('en-US', options);

  // This useEffect is used to set the default specialty when the user changes roles
  useEffect(() => {
    if (role === 'provider') {
      setSelectedSpecialty(specialties['My Dashboard (Physician)'][0]);
    }
  }, [role]);

  return (
    <WidgetContainer
      header="Welcome"
      inlineStyle={{ zIndex: '100' }}
      ref={ref}
      noContainer
      {...other}>
      <>
        {isLoading && <Loading />}
        {!isLoading && role === 'admin' && (
          <Tile className={`${styles.container} ${mutable ? styles.unlockedHover : ''}`}>
            <div className={styles.text}>
              <p className={styles.dateText}>{formattedDate}</p>
              <h1>
                Welcome,
                <br />
                {user.name}
              </h1>
            </div>
            <Dropdown
              id="teamMembers"
              arial-label="team members"
              data-cytest="dashboardDropdown"
              label=""
              onChange={({ selectedItem }: SelectedItemType) => changeDashboard(selectedItem)}
              selectedItem={selected}
              items={dashboards}
            />
            <Dropdown
              id="specialtySelector"
              arial-label="specialty"
              data-cytest="specialtyDropdown"
              label=""
              onChange={({ selectedItem }: SelectedItemType) => changeSpecialty(selectedItem)}
              selectedItem={selectedSpecialty}
              items={specialties[selected]}
            />
          </Tile>
        )}
        {!isLoading && role === 'provider' && (
          <Tile className={styles.container}>
            <div className={styles.text}>
              <p className={styles.dateText}>{formattedDate}</p>
              <h1>
                Welcome
                <br />
                {user.name}
              </h1>
            </div>
            <Dropdown
              id="specialtySelector"
              arial-label="specialty"
              label=""
              onChange={({ selectedItem }: SelectedItemType) => changeSpecialty(selectedItem)}
              selectedItem={selectedSpecialty}
              items={specialties['My Dashboard (Physician)']}
            />
          </Tile>
        )}
      </>
    </WidgetContainer>
  );
});

WelcomeMessage.displayName = 'WelcomeMessage';
export default WelcomeMessage;
