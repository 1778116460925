import InformationalTile from './InformationalTile';

const TotalActiveUsers = () => {
  return (
    <InformationalTile
      title="Total Active Users"
      value={43}
      range={70}
      valueType="isRewards"
      icon="events"
    />
  );
};

TotalActiveUsers.displayName = 'TotalActiveUsers';
export default TotalActiveUsers;
