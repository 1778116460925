import { createContext, useState, useContext, ReactNode } from 'react';

type RoleTypes = 'admin' | 'provider';
interface IRoleContext {
  role: RoleTypes;
  setRole: (state: RoleTypes) => void;
}
const RoleContext = createContext<IRoleContext>(null);
export const useRoleContext = () => useContext(RoleContext);

interface RoleProviderProps {
  children: ReactNode;
}
export const RoleProvider = ({ children }: RoleProviderProps) => {
  const [role, setRole] = useState<RoleTypes>('admin');

  return <RoleContext.Provider value={{ role, setRole }}>{children}</RoleContext.Provider>;
};
