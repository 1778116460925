import styles from './AfterSubmitResults.module.scss';
import { PieChart } from '@carbon/charts-react';
import { useBackend } from '../../../swr/useBackend';
import { useEffect, useState } from 'react';

interface AfterSubmitProps {
  subTitle: string;
  question: string;
  count?: CountItem[];
  newResponseAdded: string[];
  isFlipped: boolean;
  peerpoll?: {
    id: string;
  };
}

interface CountItem {
  peerpoll_id: string;
  selection: string;
  count: number;
}

const pieChartOptions = {
  title: ' ',
  resizable: true,
  legend: {
    enabled: true,
    alignment: 'left',
    position: 'bottom',
    orientation: 'vertical',
    truncation: {
      numCharacter: 150
    }
  },
  toolbar: {
    enabled: false
  },
  pie: {
    alignment: 'center'
  },

  height: '100%'
};

const AfterSubmitResults = ({
  question,
  subTitle,
  newResponseAdded,
  isFlipped
}: AfterSubmitProps): JSX.Element => {
  const { data } = useBackend<AfterSubmitProps>({
    endpoint: 'polls/latest',
    method: 'GET'
  });

  const { data: allCounts } = useBackend<CountItem[]>({
    endpoint: data?.peerpoll?.id ? 'polls/AllResponses/TotalAnswers/Count' : null,
    method: 'get',
    params: {
      peerpoll_id: data?.peerpoll?.id
    }
  });

  const [chartData, setChartData] = useState([]);

  const tempChartData = allCounts?.map((countItem) => {
    return {
      group: countItem.selection,
      value: countItem.count
    };
  });

  useEffect(() => {
    if (isFlipped) {
      const updatedChartData = tempChartData.map((item) => {
        for (let i = 0; i < newResponseAdded.length; i++) {
          if (item.group === newResponseAdded[i]) {
            newResponseAdded.splice(i, 1);
            return {
              group: item.group,
              value: item.value + 1
            };
          }
        }
        return item;
      });
      for (let i = 0; i < newResponseAdded.length; i++) {
        updatedChartData.push({
          group: newResponseAdded[i],
          value: 1
        });
      }
      setChartData(updatedChartData);
    }
  }, [isFlipped, newResponseAdded]);

  if (!allCounts || allCounts.length === 0) {
    return <div> Loading...</div>;
  }

  return (
    <div style={{ height: 600 }}>
      <div> {question}</div>
      <h5 style={{ fontWeight: 'bold', marginTop: '1rem' }}>{subTitle}</h5>
      <hr className={styles.hr} />
      {/* @ts-ignore */}
      <PieChart data={chartData} options={pieChartOptions} />
      <h5 className={styles.thanks}> Thank you for your feedback!</h5>
    </div>
  );
};
export default AfterSubmitResults;
