import React from 'react';
import { Tag } from '@carbon/react';
import Assignments from '../../dummyData/assignment.json';
import styles from './AssignmentTile.module.scss';
import WidgetContainer from '../WidgetContainer/WidgetContainer';

type AssignmentData = (typeof Assignments)[0];

const AssignmentTile = React.forwardRef<HTMLDivElement>(({ ...other }, ref) => {
  return (
    <WidgetContainer header="Assignments" link page="/" {...other} ref={ref}>
      {Assignments.map((Assignment: AssignmentData) => (
        <div className={styles.contentArea} key={Assignment.id}>
          <Tag type="gray">{Assignment.tag}</Tag>
          <div className={styles.textContent}>
            <div className={styles.title}>{Assignment.title}</div>
            <p className={styles.date}>Target date: {Assignment.date}</p>
          </div>
        </div>
      ))}
    </WidgetContainer>
  );
});
AssignmentTile.displayName = 'AssignmentTile';

export default AssignmentTile;
