import { Suspense, lazy } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from '@carbon/react';
import Layout from '../components/Layout/Layout';
import { Auth0ProviderWithNavigate } from '../auth/auth0-provider-with-navigate';
import Home from '../App';

const CallbackPage = lazy(
  () => import(/* webpackChunkName: "callback" */ '../pages/callback-page')
);
const ErrorPage = lazy(() => import(/* webpackChunkName: "error" */ './ErrorPage'));

const ProtectedLayout = withAuthenticationRequired(Layout, {
  onRedirecting: () => <Loading withOverlay />
});
const router = createBrowserRouter([
  {
    element: <Auth0ProviderWithNavigate />,
    errorElement: (
      <Suspense fallback={<Loading />}>
        <ErrorPage />
      </Suspense>
    ),
    children: [
      {
        path: '/',
        index: true,
        element: (
          <ProtectedLayout>
            <Home />
          </ProtectedLayout>
        )
      },
      {
        path: 'callback',
        element: (
          <Suspense fallback={<Loading />}>
            <ProtectedLayout>
              <CallbackPage />
            </ProtectedLayout>
          </Suspense>
        )
      }
    ]
  }
]);

const Routing = (): JSX.Element => {
  return <RouterProvider router={router} />;
};
export default Routing;
