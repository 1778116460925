import useSWR, { KeyedMutator } from 'swr';
import { queryBackendGet, queryBackendPost, queryBackendPut } from './fetchers';

interface SwrHookProps {
  endpoint: string;
  method: string;
  body?: object;
  params?: object;
}

interface SwrHookResult<T> {
  data?: T;
  isLoading: boolean;
  error: {
    info: object;
    status: number;
  };
  mutate: KeyedMutator<T>;
}

export const useBackend = <T,>(
  { endpoint, method, body, params }: SwrHookProps,
  options?: object
): SwrHookResult<T> => {
  const { data, error, isValidating, mutate } = useSWR<T>(
    [endpoint],
    (url: string, token: string) => {
      if (method.toUpperCase() === 'PUT') {
        return queryBackendPut(url, token, body);
      }
      if (method.toUpperCase() === 'POST') {
        return queryBackendPost(url, token, body);
      }
      if (params) {
        return queryBackendGet(url, token, Object.entries(params));
      }
      return queryBackendGet(url, token);
    },
    options
  );

  if (error) return { data: null, isLoading: false, error: error, mutate: mutate };
  if (isValidating || !data)
    return { data: undefined, isLoading: true, error: null, mutate: mutate };

  return { data, isLoading: false, error: null, mutate: mutate };
};

export const useDato = <T,>(
  { key, query }: { key: string; query: object },
  options?: object
): SwrHookResult<T> => {
  const { data, error, isValidating, mutate } = useSWR<T>(
    [key],
    (token: string) => {
      return queryBackendPost('api/content/dato_content', token, query);
    },
    options
  );

  if (error) return { data: null, isLoading: false, error: error, mutate: mutate };
  if (isValidating || !data)
    return { data: undefined, isLoading: true, error: null, mutate: mutate };

  return { data, isLoading: false, error: null, mutate: mutate };
};
