import React from 'react';
import QuickLinkTile from './QuickLinkTile';
import libraryIcon from '../../../../public/images/libraryIcon.png';
import UTDSVG from '../../../../public/images/utd.svg';
import cyberLogo from '../../../../public/images/cyberLogo.png';
import jlLogo from '../../../../public/images/jlLogo.png';
import styles from './QuickLinkTile.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import WidgetContainer from '../WidgetContainer/WidgetContainer';

const QuickLinkContainer = React.forwardRef<HTMLDivElement>(({ ...other }, ref) => {
  const { user } = useAuth0();
  return (
    <WidgetContainer
      header="Quick Links"
      page="/articles"
      {...other}
      ref={ref}
      noContainer
      noContainerStyles={styles.wc}>
      <>
        <div data-cytest="ql-1" className={styles.containerSpacing}>
          <QuickLinkTile
            href="https://www.magmutual.com/learning"
            icon={libraryIcon}
            title="Advice Library"
          />
        </div>
        <div data-cytest="ql-2" className={styles.containerSpacing}>
          <QuickLinkTile
            href={`https://www.uptodate.com/portal-login?unid=${user.sub}&srcsys=HMGR413630&eiv=2.1.0`}
            icon={UTDSVG}
            title="UpToDate"
          />
        </div>
        <div data-cytest="ql-3" className={styles.containerSpacing}>
          <QuickLinkTile
            href="https://magmutual.eplaceinc.com/?login&uoid=4650&hash=2ac9cb7dc02b3c0083eb70898e549b63"
            icon={cyberLogo}
            title="Cyber Center"
          />
        </div>
        <div data-cytest="ql-4" className={styles.containerSpacing}>
          <QuickLinkTile
            href="https://www.magmutual.com/"
            icon={jlLogo}
            title="Prevent & Protect Portal"
          />
        </div>
      </>
    </WidgetContainer>
  );
});
QuickLinkContainer.displayName = 'QuickLinkContainer';

export default QuickLinkContainer;
