import React from 'react';
import WidgetContainer from '../../DashboardWidgets/WidgetContainer/WidgetContainer';
import { Link } from '@carbon/react';
import { DataEnrichment } from '@carbon/icons-react';
import { Tile } from '@carbon/react';
import styles from './QuickAdviceStyling.module.scss';
import Slider from 'react-slick';
import { useDato } from '../../../swr/useBackend';

// Carousel Settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,

  //   changes placement of dots
  appendDots: (dots: any) => (
    <div className={styles.dots}>
      <ul> {dots} </ul>
    </div>
  )
};
interface QuickAdviceData {
  advice: string;
  hyperlink: string;
}

interface QuickAdviceDataAll {
  allQuickAdvices: QuickAdviceData[];
}

const QuickAdviceCarousel = React.forwardRef<HTMLDivElement>(({ ...other }, ref) => {
  // CMS Query for Quick Advice
  const { data, error } = useDato<QuickAdviceDataAll>({
    key: 'QuickAdvice',
    query: {
      query: `{
        allQuickAdvices(first: "4") {
          advice
          hyperlink
        }
      }`
    }
  });

  return (
    <WidgetContainer ref={ref} header="Quick Advice" page="/" {...other} noContainer>
      <Tile className={styles.tile}>
        <h4 className={styles.header}>Quick Advice</h4>
        <div className={styles.carousel}>
          <Slider {...settings} className={styles.container}>
            {error ? (
              <p>No Quick Advice Found</p>
            ) : (
              data?.allQuickAdvices.map((quickAdvice: QuickAdviceData, idx) => (
                <div key={`${quickAdvice.hyperlink}-${idx}`} className={styles.container}>
                  <p className={styles.text}>{quickAdvice.advice}</p>
                  <Link
                    className={styles.link}
                    href={quickAdvice.hyperlink}
                    target="_blank"
                    rel="noreferrer"
                    visited={false}
                    inline>
                    Learn More
                  </Link>
                </div>
              ))
            )}
          </Slider>
        </div>
        <div className={styles.iconParent}>
          <div></div>
          <div className={styles.iconContainer}>
            <DataEnrichment size="32" className={styles.svg} />
          </div>
        </div>
      </Tile>
    </WidgetContainer>
  );
});
QuickAdviceCarousel.displayName = 'QuickAdviceCarousel';

export default QuickAdviceCarousel;
