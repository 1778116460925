import { useEffect, useRef, useState, useMemo, ReactNode } from 'react';
import { Button } from '@carbon/react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useAuth0 } from '@auth0/auth0-react';
import useSWRMutation from 'swr/mutation';
import { resetToDefaultLayout, updateLayoutMutation } from '../../swr/fetchers';
import { useBackend } from '../../swr/useBackend';
import { useDashboardContext } from '../../contexts/DashboardProvider';
import { useRoleContext } from '../../contexts/RoleProvider';
import adminLayout from '../../layouts/adminLayout.json';
import providerLayout from '../../layouts/providerLayout.json';

interface RGLDashboardProps {
  children: ReactNode;
}

type LayoutShape = (typeof adminLayout)['lg'];
interface LayoutObj {
  [breakpoint: string]: LayoutShape;
}
type DBData = {
  breakpoint: string;
  role: string;
  layout: LayoutShape;
};

const RGLDashboard = ({ children }: RGLDashboardProps): JSX.Element => {
  const { mutable } = useDashboardContext();
  const { role } = useRoleContext();
  const mountedRef = useRef(false);
  const defaultLayout: LayoutObj = role === 'admin' ? adminLayout : providerLayout;
  const { data, isLoading, mutate } = useBackend<DBData[]>({
    endpoint: 'read/layout',
    method: 'get'
  });
  const { trigger } = useSWRMutation('update/layout', updateLayoutMutation);
  const { getAccessTokenSilently } = useAuth0();
  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);
  const [layouts, setLayouts] = useState<LayoutObj>();

  // NOTE: Get current rem value
  const htmlElement = document.querySelector('html');
  const rem = parseFloat(window.getComputedStyle(htmlElement).getPropertyValue('font-size'));
  const twoRem = 2 * rem;
  const paddingGutters = 2 * twoRem;
  const screenToGridDifference = paddingGutters;
  const BREAKPOINTS = {
    lg: 1056 - screenToGridDifference,
    md: 672 - screenToGridDifference,
    sm: 300 - screenToGridDifference,
    xs: 160 - screenToGridDifference,
    xxs: 0
  };
  const getBreakpoint = () => {
    if (window.innerWidth >= BREAKPOINTS.lg) {
      return 'lg';
    } else if (window.innerWidth >= BREAKPOINTS.md) {
      return 'md';
    } else if (window.innerWidth >= BREAKPOINTS.sm) {
      return 'sm';
    }
  };

  useEffect(() => {
    if (!isLoading && data && Array.isArray(data)) {
      const loadedLayouts: LayoutObj = {};
      const filteredLayouts = data.filter((layoutObj) => layoutObj.role === role);
      for (let i = 0; i < filteredLayouts.length; ++i) {
        loadedLayouts[filteredLayouts[i].breakpoint] = filteredLayouts[i].layout;
      }
      setLayouts(loadedLayouts);
    }
  }, [isLoading, data, role]);
  useEffect(() => {
    if (mountedRef.current && !mutable && data && Array.isArray(data)) {
      const layoutToUpdate = data.filter(
        (layout) => layout.breakpoint === getBreakpoint() && layout.role === role
      )[0];
      trigger({
        layout_blob: JSON.stringify(layoutToUpdate.layout),
        breakpoint: layoutToUpdate.breakpoint,
        role: role,
        getToken: getAccessTokenSilently
      });
    }
  }, [mutable]);
  // HACK: Used to not update the layout when the dropdown changes
  useEffect(() => {
    mountedRef.current = true;
  }, []);

  return (
    <>
      {!isLoading && (
        <>
          <ResponsiveGridLayout
            layouts={layouts}
            breakpoints={BREAKPOINTS}
            cols={{ lg: 4, md: 2, sm: 1, xs: 1, xxs: 1 }}
            rowHeight={280}
            compactType="vertical"
            margin={[32, 32]}
            isBounded={true}
            measureBeforeMount={true}
            isDraggable={mutable}
            isResizable={false}
            onLayoutChange={(currentLayout: LayoutShape) => {
              if (mutable && data && Array.isArray(data)) {
                const layoutToUpdate = data.filter(
                  (layout) => layout.breakpoint === getBreakpoint() && layout.role === role
                )[0];
                const layoutIndex = data.indexOf(layoutToUpdate);
                const cleanedCurrentLayout = currentLayout.map((layout) => {
                  const { i, x, y, w, h, static: still } = layout;
                  return { i: i, x: x, y: y, w: w, h: h, static: still };
                });
                const updatedEntry = {
                  ...data[layoutIndex],
                  layout: cleanedCurrentLayout
                };
                data.splice(layoutIndex, 1, updatedEntry);
                mutate([...data], { revalidate: false });
              } else if (!data) {
                setLayouts(defaultLayout);
                mutate();
              }
            }}>
            {children}
          </ResponsiveGridLayout>
          <Button
            kind="primary"
            onClick={async () =>
              resetToDefaultLayout(getAccessTokenSilently, getBreakpoint(), role).then(() =>
                window.location.reload()
              )
            }>
            Reset Layout
          </Button>
        </>
      )}
    </>
  );
};

export default RGLDashboard;
