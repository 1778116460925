import React, { useEffect, useState } from 'react';
import { Button, RadioTile, TileGroup, SelectableTile } from '@carbon/react';
import { blue } from '@carbon/colors';
import { ArrowRight, Checkmark } from '@carbon/icons-react';
import WidgetContainer from '../../DashboardWidgets/WidgetContainer/WidgetContainer';
import { useTheme } from '../../../contexts/ThemeProvider';
import styles from '../PeerPoll/PeerPoll.module.scss';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import AfterSubmitResults from './AfterSubmitResults';
import { useBackend } from '../../../swr/useBackend';
import { useAuth0 } from '@auth0/auth0-react';
import { SaveSelections } from '../../../swr/fetchers';

type PeerPollData = {
  peerpoll: {
    id: string;
    question: string;
    multiSelect: boolean;
    specialty: string;
    answerChoices: {
      choice: string;
    }[];
    maxNumberOfAllowedResponses: number;
  };
  userSelections: {
    response: number;
    userSelection: string;
  };
};

const PeerPoll = React.forwardRef<HTMLDivElement, { id: string }>(({ ...other }, ref) => {
  const { data, error, isLoading } = useBackend<PeerPollData>({
    endpoint: 'polls/latest',
    method: 'GET'
  });

  const { theme } = useTheme();
  const [selected, setSelected] = React.useState<string | null>();

  const maxNumberOfAllowedResponses = data?.peerpoll.maxNumberOfAllowedResponses;

  const [limit, setLimit] = React.useState([]); // this is the array that will hold the selected options and will be used to check if the maxNumberOfAllowedResponses has been reached.
  const handleLimit = (answer: string): void => {
    if (limit.includes(answer)) {
      setLimit(limit.filter((limit) => limit !== answer));
    } else {
      // if the limit array does not include the answer, then add it to the array.
      setLimit([...limit, answer]);
    }
  };

  const [isFlipped, setFlipped] = useState(false);
  const flip = () => {
    setFlipped(!isFlipped);
  };

  document
    .getElementById('wgt-peerpoll')
    ?.style.setProperty(
      '--peerpoll-selection',
      theme === 'dark-theme' ? `${blue[80]}` : `${blue[10]}`
    );
  document
    .getElementById('wgt-peerpoll')
    ?.style.setProperty(
      '--peerpoll-checkmark',
      theme === 'dark-theme' ? `${blue[10]}` : `${blue[70]}`
    );

  const { data: response } = useBackend<number[]>({
    endpoint: data?.peerpoll?.id ? 'polls/UserResponses' : null,
    method: 'get',
    params: {
      id: data?.peerpoll?.id
    }
  });

  const { data: backendPollSelections } = useBackend<string[]>({
    endpoint: response ? 'polls/UserSelections' : null,
    method: 'get',
    params: {
      ids: response ? response : null
    }
  });

  const [userResponse, setUserResponse] = React.useState(false);

  useEffect(() => {
    if (response && response.length > 0) {
      setFlipped(true);
      setUserResponse(true);
    } else {
      setFlipped(false);
      setUserResponse(false);
    }
  }, [response]);

  useEffect(() => {
    if (!userResponse) {
      setFlipped(false);
      setUserResponse(false);
    }
  }, [userResponse]);

  const [pollSelection, setPollSelection] = useState([]);

  const handlePollSelection = (answer: string): void => {
    if (pollSelection.includes(answer)) {
      // if the pollSelection array includes the answer, then remove it from the array.
      setPollSelection(pollSelection.filter((pollSelection) => pollSelection !== answer)); // if the pollSelection array does not include the answer, then add it to the array.
    } else {
      setPollSelection([...pollSelection, answer]); // if the pollSelection array does not include the answer, then add it to the array.
    }
  };

  const { getAccessTokenSilently } = useAuth0();

  function handleSubmit() {
    SaveSelections(getAccessTokenSilently, data?.peerpoll?.id, pollSelection);
    flip();
  }

  return (
    <WidgetContainer header="Peer Poll" page="/" {...other} ref={ref}>
      <Flippy flipOnClick={false} isFlipped={isFlipped} className={styles.container}>
        {!isLoading &&
          (error ? (
            <div>error</div>
          ) : (
            data.peerpoll && (
              <>
                <FrontSide>
                  <div className={styles.questionContainer}>
                    <p>{data.peerpoll.question}</p>
                    <hr className={styles.hr} />
                    {data.peerpoll.multiSelect ? (
                      <div role="group" className={styles.tileGroupMuti}>
                        {data.peerpoll.answerChoices.map(
                          ({ choice }: { choice: string }, idx: number) => (
                            <SelectableTile
                              key={choice + '-' + idx}
                              value={choice}
                              id={choice + '-' + idx}
                              name="tiles"
                              selected={limit.includes(choice)}
                              onClick={() => {
                                handleLimit(choice + '_' + idx);
                                handlePollSelection(choice);
                              }}
                              disabled={
                                limit.length === maxNumberOfAllowedResponses &&
                                !limit.includes(choice + '_' + idx)
                              }
                              className={styles.multiSelectOption}>
                              {choice}
                            </SelectableTile>
                          )
                        )}
                      </div>
                    ) : (
                      <TileGroup
                        name="weekly-peer-poll"
                        className={styles.tileGroupRadio}
                        onChange={(value: string) => {
                          setSelected(value);
                        }}>
                        {data.peerpoll.answerChoices.map(
                          ({ choice }: { choice: string }, idx: number) => (
                            <RadioTile
                              key={choice + '_' + idx}
                              value={choice}
                              className={`${styles.option} ${
                                selected === choice ? styles.selectedOption : ''
                              }`}
                              handlePollSelection>
                              <div className={styles.optionText}>
                                <Checkmark />
                                {choice}
                              </div>
                            </RadioTile>
                          )
                        )}
                      </TileGroup>
                    )}
                    <Button
                      kind="primary"
                      onClick={handleSubmit}
                      disabled={limit.length === 0 && !selected}
                      className={styles.buttonSpacing}>
                      <div className={styles.buttonText}>
                        Submit
                        <ArrowRight />
                      </div>
                    </Button>
                  </div>
                </FrontSide>
                <BackSide>
                  <AfterSubmitResults
                    subTitle="Peer responses"
                    question={data.peerpoll.question}
                    newResponseAdded={pollSelection}
                    isFlipped={isFlipped}
                  />
                </BackSide>
              </>
            )
          ))}
      </Flippy>
    </WidgetContainer>
  );
});

PeerPoll.displayName = 'PeerPoll';

export default PeerPoll;
